// IconAvatar.js
import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconAvatar.module.css';

const IconAvatar = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#717171" />
      <mask
        id="mask0_92_5"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="25"
        height="25"
      >
        <circle cx="15.1875" cy="14.1875" r="12.1875" fill="#737373" />
      </mask>
      <g mask="url(#mask0_92_5)">
        <circle cx="15.1875" cy="12.3125" r="6.5625" fill="white" />
        <circle cx="15.1875" cy="28.25" r="11.25" fill="white" />
      </g>
      <path
        d="M20 24L12 16L20 8"
        stroke="#112148"
        stroke-width="2.78261"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

IconAvatar.defaultProps = { className: null };

IconAvatar.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAvatar; // Ensure this is a default export
